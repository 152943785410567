import React from "react";
import { Card, Accordion } from 'react-bootstrap'
const courseinformation = (props) => {
    const renderCourseInformation = () => {

        return props.lectures.map((couse)=>(
              <Card key={couse.sys.id}>
                <Accordion.Toggle as={Card.Header} eventKey={couse.sys.id}>
                 {couse.fields.title}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={couse.sys.id}>
                <Card.Body>{couse.fields.description}</Card.Body>
                </Accordion.Collapse>
            </Card>
        ))
    }
    if(props.lectures!=null){
        return (
            <>  
                <article className="entry entry-single">
                    <h2 className="entry-title">
                        Програмата на курса
                    </h2>
                    <Accordion>
                        {renderCourseInformation()}
                    </Accordion>
                </article>    
            </>
        )
    }
    return (
        <>
        </>
    )

}

export default courseinformation;