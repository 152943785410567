const client = require('contentful').createClient({
    space: '84b5ac405mks',
    accessToken: '0e_h91uK-RW5sdb2C1V6H8-GB8WXmTOdds8cmg9Qn-s'
  })

  const getCourses = () => client.getEntries({
    content_type: 'course'
  }).then(response => response.items)

  const getCourseById = (id) => client.getEntries({
    'sys.id': id,
     content_type: 'course'
  }).then(response => response.items)

  const getTeamMembers = () => client.getEntries({
    content_type: 'team'
  }).then(response => response.items)
  
  export {getCourses, getCourseById, getTeamMembers}