import React,{useState} from "react";
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import {Spinner } from "react-bootstrap";

const Footer = () =>{
  const [isLoading, setLoading] = useState(false);
  const [sussessNewsletter, setSussessNewsletter] = useState(false);
  const [errorNewsletter, setErrorNewsletter] = useState(false);

  const renderFormInfo = () => {
    if (isLoading) return (
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    )

    return (
        <>
            <Alert variant="success" show={sussessNewsletter}>
                <Alert.Heading>Успешно се абонирахте за нашия бюлетин</Alert.Heading>
            </Alert>
            <Alert variant="danger" show={errorNewsletter}>
                <Alert.Heading>Пробрем</Alert.Heading>
                    <p>
                        Здравейте възникна проблем по време на абониранете за нашия бюлетин, моля опитайте по късно.
                    </p>
            </Alert>                
        </>
    )
    
}
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target),
    formDataObj = Object.fromEntries(formData.entries())
    const {email} = formDataObj;
    fetch("https://v1.nocodeapi.com/cefothe/google_sheets/nMjjpBzjhKuderiV?tabId=Newsletter", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([[email]])}
        )
        .then((response) =>{
            setLoading(false);
            setSussessNewsletter(true);
            setTimeout(function () {
              setSussessNewsletter(false);
          }, 3000 );
        })
        .catch((error) => {
            setLoading(false);
            setErrorNewsletter(true);
            setTimeout(function () {
              setErrorNewsletter(false);
          }, 3000 );
        });
  } 
  return(
    <footer id="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-4 col-md-6 footer-contact">
              <h3>IT different courses</h3>
              <p>
              Търговски център Гранд,
              улица „Капитан Райчо“ №56 Пловдив, България<br/>
                <strong>Телефон:</strong> +359 895 15 16 15<br/>
                <strong>Телефон:</strong> +359 878 67 26 31<br/>
                <strong>Mail:</strong> info@it-different-courses.com<br/>
              </p>
            </div>

            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Полезни линкове</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <Link to="/">Начало</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/team">Екип</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/courses">Курсове</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Контакти</Link></li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Присъединете се към нашия бюлетин</h4>
              {renderFormInfo()}
              <form onSubmit={handleSubmit}>
                <input type="email" name="email" required /><input type="submit" value="Присъедини се" />
              </form>
            </div>

          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">

        <div className="mr-md-auto text-center text-md-left">
          <div className="copyright">
            &copy; {(new Date().getFullYear())} <strong><span>"Различните Курсове" ООД</span></strong>. всички права запазени!
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://www.facebook.com/itdifferentcourses" target="_blank" rel="noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
          <a href="https://www.linkedin.com/company/70926000" target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
        </div>
      </div>
    </footer>
  )
}
export default Footer;