import React from "react";

const lectorCourse = (props) =>{
    return (
        <div className="blog-author clearfix aos-init aos-animate" data-aos="fade-up">
            <img src={props.lector.fields.image.fields.file.url} className="rounded-circle float-left" alt="" />
            <h4>{props.lector.fields.name}</h4>
            <div className="social-links">
                <a href={props.lector.fields.linkedin}><i className="icofont-linkedin"></i></a>
            </div>
            <p>{props.lector.fields.description}</p>
        </div>
    )   
}
export default lectorCourse