import React from "react";
const priceSection = (props) =>{
    return (
        <>
        <article className="entry entry-single price-section">
            <div className="blog-details-content" >
                <div className="author-comment">
                            <div className="author-designation-comment">
                                        <div className="section-title-2 mb20 headline text-left">
                        <h2>Цена на <span>обучението</span></h2>
                    </div>
                    <div className="section-title-2 mb20 headline text-left">
                            <div className="row">
                            <div className="col-md-9 mt-2 mb-2">
                                <h3>Отдалечено присъствие / Дистанционна форма</h3>
                                </div>
                            <div className="col-md-3 mt-2 mb-2">
                                    <h3>{props.price} лв.</h3>
                            </div>
                            </div>
                            <hr></hr>
                            <p className="pt-3"></p>
                    </div>
                    </div>
                </div>
                </div>
            </article>
        </>
    )
}
export default priceSection;