import React from "react";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const pageNotFound = () => {
    return (
        <>
        <Helmet>
            <title>Страницата не е намерена - IT DIFFERENT COURSES</title>
            <link rel="canonical" href="https://it-different-courses.com" />
            <meta property="og:locale" content="bg_BG" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Страницата не е намерена - IT DIFFERENT COURSES" />
            <meta property="og:url" content="https://it-different-courses.com" />
            <meta property="og:site_name" content="it-different-courses.com" />
        </Helmet>
        <section id="services" className="services section-bg section-notfound">
            <div className="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <span class="display-1 d-block">404</span>
                        <div class="mb-4 lead">Страницата, която търсите, не е намерена.</div>
                        <Link to="/" class="btn btn-link">Към началната страница</Link>
                    </div>  
                </div>
            </div>
        </section>
        </>
    )
}

export default pageNotFound;