import React, {useState, useLayoutEffect} from "react";
import {Helmet} from "react-helmet";
import Alert from 'react-bootstrap/Alert';
import {Spinner } from "react-bootstrap";

const Contact = () => {
    const [sussessContact, setSussessContact]= useState(false);
    const [errorContact, setErrorContact]= useState(false);
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const renderFormInfo = () => {
        if (loading) return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )

        return (
            <>
                <Alert variant="success" show={sussessContact}>
                    <Alert.Heading>Успешно прието запитване</Alert.Heading>
                        <p>
                            Нашия екип ще се свърже със Вас в следващите 24 часа.
                        </p>
                </Alert>
                <Alert variant="danger" show={errorContact}>
                    <Alert.Heading>Проблем при запитването</Alert.Heading>
                        <p>
                            Здравейте възникна проблем по време на запитването Ви, моля опитайте по късно.
                        </p>
                </Alert>                
            </>
        )
        
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
        const {name, email, subject, message} = formDataObj;
        fetch("https://v1.nocodeapi.com/cefothe/google_sheets/nMjjpBzjhKuderiV?tabId=Contacts", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([[name, email, subject, message]])}
            )
            .then((response) =>{
                setLoading(false);
                setSussessContact(true);
            })
            .catch((error) => {
                setLoading(false);
                setErrorContact(true);
            });
      }
    return (
        <>
        <section id="breadcrumbs" className="breadcrumbs">
        <Helmet>
             <title>Контакти - IT DIFFERENT COURSES</title>
             <meta name="description" content="Потърси ни по email или телефон за безплатна консултация!" />
             <meta property="og:locale" content="bg_BG" />
	         <meta property="og:type" content="article" />
             <meta property="og:title" content="Контакти - IT DIFFERENT COURSES" />
	         <meta property="og:description" content="Потърси ни по email или телефон за безплатна консултация!" />
             <meta property="og:url" content="https://it-different-courses.com/contact" />
	         <meta property="og:site_name" content="it-different-courses.com" />
        </Helmet>
        <div className="container">

            <div className="d-flex justify-content-between align-items-center breadcrumbs-configuration">
            <h2>Контакти</h2>
            <ol>
                <li><a href="/">Начало</a></li>
                <li>Контакти</li>
            </ol>
            </div>

        </div>
        </section>

        <div className="map-section">
        <iframe title="Google location" style={{border:'0', width: '100%', height: '350px'}} src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1z0KLRitGA0LPQvtCy0YHQutC4INGG0LXQvdGC0YrRgCDQk9GA0LDQvdC0INGD0LvQuNGG0LAg4oCe0JrQsNC_0LjRgtCw0L0g0KDQsNC50YfQvuKAnCDihJY1NiDQn9C70L7QstC00LjQsiwg0JHRitC70LPQsNGA0LjRjw!5e0!6i17" frameBorder="0" allowFullScreen></iframe>
        </div>

        <section id="contact" className="contact">
        <div className="container">

            <div className="row justify-content-center" >
            <div className="col-lg-10">

                <div className="info-wrap">
                <div className="row">
                    <div className="col-lg-4 info">
                    <i className="icofont-google-map"></i>
                    <h4>Място:</h4>
                    <p>Търговски център Гранд <br/>
                     улица „Капитан Райчо“ №56 Пловдив, България</p>
                    </div>

                    <div className="col-lg-4 info mt-4 mt-lg-0">
                    <i className="icofont-envelope"></i>
                    <h4>Емайл:</h4>
                    <p>info@it-different-courses.com<br/>contact@it-different-courses.com</p>
                    </div>

                    <div className="col-lg-4 info mt-4 mt-lg-0">
                    <i className="icofont-phone"></i>
                    <h4>Обадете се:</h4>
                    <p>+359 (0) 895 15 16 15 <br/>+359 (0) 878 67 26 31</p>
                    </div>
                </div>
                </div>

            </div>

            </div>

            <div className="row mt-5 justify-content-center" >
            <div className="col-lg-10">
                {renderFormInfo()}
                <form onSubmit={handleSubmit} className="php-email-form">
                <div className="form-row">
                    <div className="col-md-6 form-group">
                    <input type="text" name="name"  className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required/>
                    <div className="validate"></div>
                    </div>
                    <div className="col-md-6 form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required/>
                    <div className="validate"></div>
                    </div>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" required/>
                    <div className="validate"></div>
                </div>
                <div className="form-group">
                    <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message" required></textarea>
                    <div className="validate"></div>
                </div>
                <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
                </form>
            </div>

            </div>

        </div>
    </section>
    </>
    );
}

export default Contact;