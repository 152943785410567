import React,{useLayoutEffect} from "react";
import Carousel from "../../components/Carousel";
import AboutUs from "../../components/AboutUs";
import {Helmet} from "react-helmet";

const Home = () => { 
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <Helmet>
                <title>Курсове по програмиране от IT DIFFERENT COURSES</title>
                <meta name="description" content="IT курсове за начинаещи и корпоративни обучения по програмиране с Java, C#, Бази данни, Web програмиране с JavaScript" />
                <link rel="canonical" href="https://it-different-courses.com" />
                <meta property="og:locale" content="bg_BG" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Курсове по програмиране от IT DIFFERENT COURSES" />
                <meta property="og:description" content="IT курсове за начинаещи и корпоративни обучения по програмиране с Java, C#, Бази данни, Web програмиране с JavaScript" />
                <meta property="og:url" content="https://it-different-courses.com" />
                <meta property="og:site_name" content="it-different-courses.com" />
            </Helmet>
            <div id="hero">
                <Carousel/>
            </div>
            <AboutUs/>
        </>
    )
}

export default Home;