import React from "react";
const moreInfoCourse = () => {
    return (
        <>
            <article className="entry entry-single">
                    <h2 className="entry-title">
                        Повече информация за курса
                    </h2>
                    <p>
                        При провеждането на курса ще използваме следните приложения които ще улеснят провеждането на курса.
                    </p>
                    <div  className="container testimonials section-bg">

                    <div className="row">

                    <div className="col-lg-12 aos-init aos-animate">
                        <div className="testimonial-item">
                        <img src="/img/Moodle-Logo-RGB.png" className="testimonial-img" alt="" />
                        <h3>Eлектронен учебник</h3>
                        <p>
                             В електронния учебник, вие ще намерите всички теоретични материали от занятията както и записа от занятията.
                        </p>
                        </div>
                    </div>

                    <div className="col-lg-12 aos-init aos-animate">
                        <div className="testimonial-item mt-4 mt-lg-0">
                        <img src="/img/Zoom+logo.png" className="testimonial-img" alt="" />
                        <h3>Платформа за видео конферентни разговори</h3>
                        <p>
                           С помощта на платформана Zoom ще усъществяваме занятията в реално време и Вие ще може да комуникирате директо с лекторите.
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-12 aos-init aos-animate">
                        <div className="testimonial-item mt-4 mt-lg-0">
                        <img src="/img/5cb480b85f1b6d3fbadece78.png" className="testimonial-img" alt="" />
                        <h3>Платформа за чат комуникация</h3>
                        <p>
                           С помощта на платформата Slack ще може да комуникирате със всички от курса и ще може да питате въпроси към лектора по всяко време.
                        </p>
                        </div>
                    </div>
                    <div className="col-lg-12 aos-init aos-animate" >
                        <div className="testimonial-item mt-4 mt-lg-0">
                        <img src="/img/1280px-Git-logo.svg.png" className="testimonial-img" alt="" />
                        <h3>Система за контрол на кода</h3>
                        <p>
                           Всички курсисте че използват GitHub, където ще качват решенията на домашните си за да може да ги проверяваме и да даваме обратна връзка
                        </p>
                        </div>
                    </div>

                    </div>

                </div>    
            </article> 
        </>
    )
}
export default moreInfoCourse;