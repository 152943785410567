import React, {useState, useEffect, useLayoutEffect} from "react";
import { CardDeck, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { getCourses } from '../../cms/contentful';
import Spinner from "react-bootstrap/Spinner";
import {Helmet} from "react-helmet";

const Courses = () => {

    const [courses, setCourses] = useState([]);
    const [isLoading, setLoading] = useState(true)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        getCourses().then(courses => {
            setCourses(courses);
            setLoading(false)
        })
      }, [])

    const renderCourses = () => {
        if (isLoading) return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
        return courses.map(course=>(
            <Card key={course.sys.id} className="col-lg-4">
                    <Card.Img variant="top" src= {course.fields.image.fields.file.url} />
                    <Card.Body>
                    <Card.Title>{course.fields.name}</Card.Title>
                    <Card.Text>{course.fields.shortDescription}</Card.Text>
                    <Link to={`courses/${course.sys.id}`}>
                        <Button variant="primary">Повече информация</Button>
                    </Link>
                    </Card.Body>
            </Card>
        ))
    }  

    return (
        <>
        <section id="breadcrumbs" className="breadcrumbs">
            <Helmet>
                <title>Курсове по програмиране - IT DIFFERENT COURSES</title>
                <meta name="description" content="Online курсове. Дистанционни обучения по програмиране с Java, C#, Бази данни, Web програмиране с JavaScript" />
                <meta property="og:locale" content="bg_BG" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Курсове по програмиране - IT DIFFERENT COURSES" />
                <meta property="og:description" content="Online курсове. Дистанционни обучения по програмиране с Java, C#, Бази данни, Web програмиране с JavaScript" />
                <meta property="og:url" content="https://it-different-courses.com/courses" />
                <meta property="og:site_name" content="it-different-courses.com" />
            </Helmet>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center breadcrumbs-configuration">
                    <h2>Курсове</h2>
                    <ol>
                        <li><Link to="/">Начало</Link></li>
                        <li>Курсове</li>
                    </ol>
                </div>

            </div>
            </section>
            <section id="services" className="services section-bg">
                <div className="container">
                    <div className="row">
                        <CardDeck>
                            {renderCourses()}
                        </CardDeck>
                    </div>
                </div>
            </section>
        </>
        )
    }
export default Courses;