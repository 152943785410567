import React, {useState, useEffect, useLayoutEffect} from "react";
import { Link, useParams } from 'react-router-dom';
import { getCourseById } from '../../cms/contentful';
import CourseInformation from "../../components/CourseInformation";
import PriceSection from "../../components/PriceSection";
import LectorCourse from "../../components/LectorCourse";
import MoreInfoCouse from "../../components/MoreInfoCourse";
import {Spinner,Button } from "react-bootstrap";
import MD from 'react-markdown';
import moment from 'moment';
import 'moment/locale/bg';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert'
import {Helmet} from "react-helmet";


const SingelCourse = () => {

    let {id} = useParams();
    const [course, setCourse] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isLoadingRegistation, setLoadingRegistation] = useState(false);
    const [show, setShow] = useState(false);
    const [sussessRegistation, setSussessRegistation]= useState(false);
    const [errorRegistation, setErrorRegistation]= useState(false);
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const registation = e => {
        setLoadingRegistation(true);
        e.preventDefault()
        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())
        const {email, name, number} = formDataObj;
        fetch(course.fields.registationLink, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([[email, name, number]])}
            )
            .then((response) =>{
                setLoadingRegistation(false);
                setSussessRegistation(true);
                setTimeout(function () {
                    setSussessRegistation(false);
                    handleClose()
                }, 3000 );
            })
            .catch((error) => {
                setLoadingRegistation(false);
                setErrorRegistation(true);
                setTimeout(function () {
                    setErrorRegistation(false);
                    handleClose()
                }, 3000)
            });
      }

    useEffect(() => {
        getCourseById(id).then(result => {
            setCourse(result[0]);
            setLoading(false)
        })
      }, [id])

    const renderRegistationMessage = () =>{
        if (isLoadingRegistation) return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
        return (
            <>
                <Alert variant="success" show={sussessRegistation}>
                    <Alert.Heading>Успешна регистрация</Alert.Heading>
                        <p>
                            Вие успешно се регисрирахте, нашия екип ще се свърже със Вас в следващите 24 часа.
                        </p>
                </Alert>
                <Alert variant="danger" show={errorRegistation}>
                    <Alert.Heading>Проблем при регистрацията</Alert.Heading>
                        <p>
                            Здравейте възникна проблем по време на регистрацията Ви, моля опитайте по късно.
                        </p>
                </Alert>
            </>
        )
    }  

    const renderCourse = () =>{
        if (isLoading) return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
        return (
                <>
                    <Helmet>
                        <title>{course.fields.name} - IT DIFFERENT COURSES</title>
                        <meta name="description" content={course.fields.shortDescription}/>
                        <meta property="og:locale" content="bg_BG" />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content={course.fields.name +" - IT DIFFERENT COURSES"} />
                        <meta property="og:description" content={course.fields.shortDescription} />
                        <meta property="og:url" content={"https://it-different-courses.com/courses/"+id} />
                        <meta property="og:site_name" content="it-different-courses.com" />
                     </Helmet>   
                    <div className="col-lg-8 entries">
                        <article className="entry entry-single" >
                        <div className="entry-img">
                            <img src= {course.fields.image.fields.file.url} alt="" className="img-fluid" />
                        </div>
                        <h2 className="entry-title">
                            {course.fields.name}
                        </h2>
                        <div className="entry-content">
                            <MD source={course.fields.description} />
                        </div>
                        </article>
                        <CourseInformation lectures={course.fields.lectures}/>
                        <PriceSection price={course.fields.price}/>
                        <LectorCourse lector={course.fields.lector} />
                        <MoreInfoCouse />
                    </div>
                    <div className="col-lg-4 entries">
                        <div className="sidebar">
                            <h3 className="sidebar-title">Предстоящи Групи</h3>
                            <div className="course-side-bar-widget">
                                    <h3>  {moment(course.fields.startdate).format("LL")}</h3>
                                </div> 
                            <div className="couse-feature ul-li-block">
                                    <ul>
                                      <li>Макс. брой <span>25</span></li>
                                        
                                        <li>Диплома  <span>от академията</span></li>
                                         <li>Форма <span>Отдалечено присъствие</span></li>
                                       </ul>
                                       
                             </div>
                             <div className="couse-feature">
                                <Button variant="success" onClick={handleShow}>Запиши се в групата</Button>
                             </div>
                            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton>
                                
                                <Modal.Title>Регистрирай се</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {renderRegistationMessage()}
                                <Form onSubmit={registation}>
                                    <Form.Group controlId="formBasicEmail" >
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" name="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Group controlId="formName">
                                        <Form.Label>Три имена</Form.Label>
                                        <Form.Control required type="text" name="name" placeholder="Три имена" />
                                    </Form.Group>
                                    <Form.Group controlId="forTelephone">
                                        <Form.Label>Телефон за контакти</Form.Label>
                                        <Form.Control required type="text" name="number" placeholder="Телефон за контакти" />
                                    </Form.Group>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Затвори
                                        </Button>
                                        <Button variant="primary" type="submit">Регистрирай се</Button>
                                    </Modal.Footer>                                    
                                    </Form>
                                </Modal.Body>

                            </Modal>
                        </div>
                    </div>
                </>
           
        )
    }

    return(
        <>
        <section id="breadcrumbs" className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center breadcrumbs-configuration">
                    <h2>Курсове</h2>
                    <ol>
                        <li><Link to="/">Начало</Link></li>
                        <li>Курсове</li>
                    </ol>
                </div>

            </div>
            </section>

            <section id="blog" className="blog">
                <div className="container">

                    <div className="row">
                        {renderCourse()}
                    </div>

                </div>
            </section>
        </>
    )
}

export default SingelCourse;