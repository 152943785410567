import React from "react";
import { NavLink, Link} from 'react-router-dom';

const header = () =>(
    <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center">

      <Link to="/" className="logo mr-auto"><img src="/img/logo-new.png" alt="" className="img-fluid"/></Link>
      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li><NavLink activeClassName="active" to="/" exact>Начало</NavLink></li>
          <li><NavLink activeClassName="active" to="/team">Екип</NavLink></li>
          <li><NavLink activeClassName="active" to="/courses">Курсове</NavLink></li>
          <li><NavLink activeClassName="active" to="/contact">Контакти</NavLink></li>

        </ul>
      </nav>

      <div className="header-social-links">
        <a href="https://www.facebook.com/itdifferentcourses" target="_blank" rel="noreferrer" className="facebook"><i className="icofont-facebook"></i></a>
        <a href="https://www.linkedin.com/company/70926000" target="_blank" rel="noreferrer" className="linkedin"><i className="icofont-linkedin"></i></a>
      </div>
    </div>  
    </header>
)

export default header;