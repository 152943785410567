import React, {useState, useEffect, useLayoutEffect} from "react";
import { Link } from 'react-router-dom';
import { getTeamMembers } from '../../cms/contentful';
import Spinner from "react-bootstrap/Spinner";
import {Helmet} from "react-helmet";

const Team = () => {

    const [teams, setTeams] = useState([]);
    const [isLoading, setLoading] = useState(true)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    useEffect(() => {
        getTeamMembers().then(teams => {
            setTeams(teams);
            setLoading(false)
        })
      }, [])

      const renderTeams = () => {
        if (isLoading) return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
        return teams.map(team=>(
            <div key={team.fields.name} className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" >
                <div className="member-img">
                    <img src={team.fields.image.fields.file.url} className="img-fluid" alt=""/>
                    <div className="social">
                    <Link to={{pathname:team.fields.linkedin}}><i className="icofont-linkedin"></i></Link>
                    </div>
                </div>
                <div className="member-info">
                    <h4>{team.fields.name}</h4>
                    <span>{team.fields.subject}</span>
                </div>
                </div>
            </div>
        ))
    }    
    return (
        <>
        <section id="breadcrumbs" className="breadcrumbs">
        <Helmet>
             <title>Нашия екип - IT DIFFERENT COURSES</title>
             <meta name="description" content="Екипът ни разполага с дългогодишен опит в сферата на софтуерните технологии." />
             <meta property="og:locale" content="bg_BG" />
	         <meta property="og:type" content="article" />
             <meta property="og:title" content="Нашия екип - IT DIFFERENT COURSES" />
	         <meta property="og:description" content="Екипът ни разполага с дългогодишен опит в сферата на софтуерните технологии." />
             <meta property="og:url" content="https://it-different-courses.com/team" />
	         <meta property="og:site_name" content="it-different-courses.com" />
        </Helmet>
        <div className="container">

            <div className="d-flex justify-content-between align-items-center breadcrumbs-configuration">
            <h2>Екип</h2>
            <ol>
                <li><Link to="/">Начало</Link></li>
                <li>Екип</li>
            </ol>
            </div>

        </div>
        </section>


        <section id="team" className="team section-bg">
        <div className="container">

            <div className="section-title" >
            <h2>НАШИЯ <strong>ЕКИП</strong></h2>
            </div>

            <div className="row">
                {renderTeams()}  
            </div>

        </div>
        </section>

        </>
    )
}

export default Team;