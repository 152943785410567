import React,{useEffect} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import Team from "./pages/Team";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Contact from "./pages/Contact";
import Courses from "./pages/Courses";
import SingelCourse from "./pages/SingelCourse";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = "UA-125318186-3";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    console.log(window.location.pathname)
  }, [])
  return (
    <>
    <GoogleReCaptchaProvider reCaptchaKey="6Ld1Gh0aAAAAAPV-E7V_dAfFsxuoGPEpR3T1F8zY">
      <Router history={history}>
        <Header />
          <main id="main">
              <Switch>
                  <Route path="/team">
                    <Team />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                  <Route exact path="/courses">
                    <Courses />
                  </Route>
                  <Route path="/courses/:id">
                    <SingelCourse/>
                  </Route>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route>
                    <PageNotFound />
                  </Route>
                  
              </Switch>
            </main>
        <Footer/>
      </Router>
    </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
