import React from "react";

const aboutUs = () =>(
<section id="about-us" className="about-us">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-title">
          <h2>За нас</h2>
        </div>
        <div className="row content">
          <div className="col-lg-6 aos-init aos-animate" data-aos="fade-right">
            <h2>IT DIFFERENT COURSES</h2>
            <h3>Нашата цел е да направим курсовете по програмиране различни</h3>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 aos-init aos-animate" data-aos="fade-left">
            <p>
              Ние сме нова академия по програмиране, която има за цел за разчупи сухата теория, с много упражнения
              и стремеж към качествения код още от самото начало. Вярваме че ако нашите курсисти се научат да пишат
              качествен код от самото начало ще е ползотворно за тях.
            </p>
            <ul>
              <li><i className="ri-check-double-line"></i> Теория която обхваща материала</li>
              <li><i className="ri-check-double-line"></i> Много упражнения</li>
              <li><i className="ri-check-double-line"></i> Близка до реалната среда в проектите</li>
            </ul>
            <p className="font-italic">
              Програмираме докато се забавлявамe
            </p>
          </div>
        </div>
      </div>
    </section>
    )
export default aboutUs;