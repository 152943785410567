import React from "react";
import Carousel from 'react-bootstrap/Carousel'

const carousel = () =>(
    <Carousel>
    <Carousel.Item>
      <img
        className="d-block"
        src="img/slide/slide-1.jpg"
        alt="First slide"
      />
      <div className="carousel-container">
      <Carousel.Caption className="carousel-content">
        <h3>Въведение в програмирането със Java</h3>
        <p>Езикът JAVA е един от най-разпространените езици за програмиране.
             В световен мащаб търсенето за JAVA developers се увеличава</p>
      </Carousel.Caption>
      </div>
    </Carousel.Item>

  </Carousel>
    )
export default carousel;